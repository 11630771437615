<template>
  <div class="chart-container w-100">
    <apexchart
      v-if="series.length > 0"
      type="polarArea"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
export default {
  name: 'GenderPolarChart',
  props: {
    chartData: {
      type: Array,
      required: true
    }
  },
  computed: {
    series() {
      return this.chartData.map(item => item.headcount)
    },
    chartOptions() {
      return {
        chart: {
          type: 'polarArea'
        },
        labels: this.chartData.map(item => item.gender),
        legend: {
          position: 'bottom',
          horizontalAlign: 'center'
        },
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },
        dataLabels: {
          enabled: false
        }
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  min-height: 350px;
}
</style>