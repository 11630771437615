<template>
  <div class="chart-container w-100">
    <apexchart v-if="chartOptions.colors.length > 0 && chartData && chartData.length"
               ref="turnoverChart"
               type="bar"
               :height="350"
               :options="chartOptions"
               :series="formattedSeries">
    </apexchart>
  </div>
</template>

<script>
export default {
  name: 'HiresLeaversChart',
  props: {
    chartData: {
      type: Array,
      required: true
    },
    companyPrimaryColor: {
      type: String,
      default: '#00875A'
    }
  },
  computed: {
    formattedSeries() {
      if (!this.chartData) return [];
      
      return [
        {
          name: 'New Hires',
          data: this.chartData.map(item => ({
            x: item.month_label,
            y: item.new_hires,
            fillColor: this.companyPrimaryColor,
            dataLabelPrefix: '+'
          }))
        },
        {
          name: 'Leavers',
          data: this.chartData.map(item => ({
            x: item.month_label,
            y: -item.leavers,
            fillColor: '#FF4560',
            dataLabelPrefix: '-'
          }))
        }
      ]
    }
  },
  data() {
    return {
      chartOptions: {
        chart: {
          type: 'bar',
          stacked: true,
          animations: {
            initialAnimation: { enabled: true },
            speed: 200
          },
          toolbar: {
            show: false
          },
          parentHeightOffset: 0
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '65%',
            borderRadius: 0,
            radiusOnLastStackedBar: false,
            dataLabels: {
              position: 'center'
            }
          }
        },
        states: {
          hover: {
            filter: {
              type: 'darken',
              value: 0.9
            }
          }
        },
        colors: [],
        dataLabels: {
          enabled: true,
          formatter: function(val, opts) {
            const prefix = opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex].dataLabelPrefix
            return prefix + Math.abs(val)
          },
          style: {
            fontSize: '12px',
            fontFamily: 'QuickSand, sans-serif',
            fontWeight: 600,
            colors: ['#fff']
          }
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          fontSize: '14px',
          fontFamily: 'QuickSand, sans-serif',
          markers: {
            radius: 12
          }
        },
        xaxis: {
          type: 'category',
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'QuickSand, sans-serif',
              fontWeight: 400
            },
            rotate: 0
          },
          axisBorder: {
            show: false
          }
        },
        yaxis: {
          labels: {
            style: {
              fontSize: '12px',
              fontFamily: 'QuickSand, sans-serif',
              fontWeight: 400
            },
            formatter: function(val) {
              return Math.abs(val)
            }
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function(val) {
              return Math.abs(val)
            }
          }
        }
      }
    }
  },
  mounted() {
    this.chartOptions.colors = [this.companyPrimaryColor, '#FF4560']
  },
  watch: {
    chartData: {
      handler(newVal) {
        if (newVal && newVal.length && this.$refs.turnoverChart) {
          this.$refs.turnoverChart.updateSeries(this.formattedSeries)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  min-height: 350px;
}
</style>