<template>
  <div class="chart-container w-100">
    <apexchart
      v-if="chartData.length"
      type="bar"
      height="250"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
export default {
  name: 'HeadcountDepartmentsChart',
  props: {
    chartData: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedData() {
      return [...this.chartData].sort((a, b) => a.sort_order - b.sort_order)
    },
    series() {
      return [{
        data: this.sortedData.map(item => item.count)
      }]
    },
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'center'
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val || ''
          },
          style: {
            colors: ['#fff']
          }
        },
        xaxis: {
          categories: this.sortedData.map(item => item.category)
        },
        colors: ['#008FFB']
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  min-height: 250px;
}
</style>