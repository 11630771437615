<template>
  <div class="chart-container w-100">
    <apexchart
      v-if="series.length > 0"
      type="bar"
      height="400"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
export default {
  name: 'HeadcountContractChart',
  props: {
    chartData: {
      type: Object,
      required: true
    }
  },
  computed: {
    series() {
      if (!this.chartData) return []
      
      const contractTypes = ['40+ hours', '33-39 hours', '28-32 hours', '17-27 hours', '1-16 hours', 'Flexible', 'Unknown']
      const ageGroups = Object.keys(this.chartData).sort((a, b) => 
        this.chartData[a].age_sort - this.chartData[b].age_sort
      )
      
      return contractTypes.map(contractType => ({
        name: contractType,
        data: ageGroups.map(age => this.chartData[age].contracts[contractType].count)
      }))
    },
    
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          stacked: true,
          toolbar: { show: false }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%'
          }
        },
        stroke: {
          width: 2
        },
        xaxis: {
          categories: Object.keys(this.chartData).sort((a, b) => 
            this.chartData[a].age_sort - this.chartData[b].age_sort
          ),
          labels: {
            rotate: 0
          }
        },
        yaxis: {
          title: {
            text: 'Number of Employees'
          }
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + " employees"
            }
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function(val) {
            return val || ''
          }
        },
        colors: ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a']
      }
    }
  }
}
</script>

<style scoped>
.chart-container {
  position: relative;
  width: 100%;
  min-height: 400px;
}
</style>