<template>
  <div>
    <CRow>
      <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="pb-0">
        <div class="d-flex">
          <h1 class="dashboard_page_title flex-grow-1">Turnover</h1>
          <div v-if="showDownloadButtons" class="insights_download_buttons text-right">
            <div class="mt-1 mt-xl-0 d-inline-block align-top">
              <CButton class="link m-0" color="primary" @click="makeScreenshot()">
                <i class="fas fa-image mr-1"/><span>{{ $t('insights.Save_as_image') }}</span>
              </CButton>                
            </div>
          </div>
        </div>
      </CCol>
    </CRow>

<CRow ref="screenshotContent" class="insights" v-bind:class="{'printing' : printingInsights}">
    
      <CCol cols="12" xl="12" lg="12" md="12" sm="12">
        <div class="insights_filters">
              <CRow v-for="(filter, index) in insightsFilters" v-bind:key="index">
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <div class="mb-2 d-flex filters">
                    <div>
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.type" 
                                   :options="groupFilterTypes" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_filter_type')" 
                                   :selectLabel="$t('common.Add_filter_type')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_filter_type')"
                                   track-by="type_tag" 
                                   label="type_name"
                                   :custom-label="customFilterTypeLabel"
                                   :disabled="filter.type !== null && filter.type !== undefined"
                                   @input="setupTypeFilter(filter.type.type_tag, index);">
                        <span slot="noResult">{{ $t('common.no_types_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>

                    <div v-if="filter.type" class="ml-2" v-bind:class="{'mr-2' : filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)}">
                      <multiselect class="data_table open_absolute"
                                   v-model="filter.condition" 
                                   :options="typeFilterConditions" 
                                   :multiple="false"                
                                   :placeholder="$t('common.Select_filter_condition')" 
                                   :selectLabel="$t('common.Add_filter_condition')" 
                                   :selectedLabel="$t('Added')"
                                   :deselectLabel="$t('common.Remove_filter_condition')"
                                   track-by="condition_tag" 
                                   label="condition_name"
                                   :custom-label="customFilterConditionLabel"
                                   :disabled="filter.condition !== null && filter.condition !== undefined"
                                   @input="filter.condition && conditionsWithoutValue.includes(filter.condition.condition_tag) ? getTurnoverData() : null;">
                        <span slot="noResult">{{ $t('common.no_conditions_found') }}</span>
                        <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                      </multiselect>
                    </div>

                    <div v-if="filter.type && filter.condition && !conditionsWithoutValue.includes(filter.condition.condition_tag)">
                      <div v-if="filter.type.type_tag == 'department'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterDepartments" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_department')"
                                     :selectLabel="$t('common.Add_department')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_department')"
                                     track-by="department_id" 
                                     label="department_name"
                                     @search-change="asyncFindDepartment"
                                     @input="getTurnoverData();">
                          <span slot="noResult">{{ $t('common.no_departments_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>
                      <div v-if="filter.type.type_tag == 'team'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterTeams" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_team')" 
                                     :selectLabel="$t('common.Add_team')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_team')"
                                     track-by="team_id" 
                                     label="team_name"
                                     @search-change="asyncFindTeam"
                                     @input="getTurnoverData();">
                          <span slot="noResult">{{ $t('common.no_teams_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>
                      <div v-if="filter.type.type_tag == 'user'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterUsers" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_user')" 
                                     :selectLabel="$t('common.Add_user')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_user')"
                                     track-by="user_id_external" 
                                     label="label"                        
                                     @search-change="asyncFindUser"
                                     @input="getTurnoverData();">
                          <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>  
                      </div>
                      <div v-if="filter.type.type_tag == 'department_manager'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterDepartmentManagers" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Set_manager')" 
                                     :selectLabel="$t('common.Select_manager')" 
                                     :selectedLabel="$t('Selected')"
                                     :deselectLabel="$t('common.Deselect_manager')"
                                     track-by="user_id_external" 
                                     label="label"                        
                                     @search-change="asyncFindDepartmentManager"
                                     @input="getTurnoverData();">
                          <span slot="noResult">{{ $t('common.No_managers_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>                               
                      <div v-if="filter.type.type_tag == 'team_manager'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterTeamManagers" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Set_employee')" 
                                     :selectLabel="$t('common.Select_employee')" 
                                     :selectedLabel="$t('Selected')"
                                     :deselectLabel="$t('common.Deselect_employee')"
                                     track-by="user_id_external" 
                                     label="label"                        
                                     @search-change="asyncFindTeamManager"
                                     @input="getTurnoverData();">
                          <span slot="noResult">{{ $t('common.no_users_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>
                      </div>
                      <div v-if="filter.type.type_tag == 'target_group'">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="groupFilterTargetGroups" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_group')" 
                                     :selectLabel="$t('common.Add_group')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_group')"
                                     track-by="group_id" 
                                     label="title"
                                     @search-change="asyncFindTargetGroup"
                                     @input="getTurnoverData();">
                          <span slot="noResult">{{ $t('common.no_groups_found') }}</span>
                          <span slot="noOptions">{{ $t('common.start_typing_to_search') }}</span>
                        </multiselect>  
                      </div>
                      <div v-if="['date_started', 'date_left'].includes(filter.type.type_tag)">
                        <multiselect class="data_table open_absolute"
                                     v-model="filter.value" 
                                     :options="typeFilterValues" 
                                     :multiple="false"                
                                     :placeholder="$t('common.Select_filter_value')" 
                                     :selectLabel="$t('common.Add_filter_value')" 
                                     :selectedLabel="$t('Added')"
                                     :deselectLabel="$t('common.Remove_filter_value')"
                                     track-by="type_value_tag" 
                                     label="type_value_name"
                                     :custom-label="customFilterTypeValueLabel"
                                     @input="getTurnoverData();">
                          <span slot="noResult">{{ $t('common.no_values_found') }}</span>
                        </multiselect>                        
                      </div>
                      <div v-if="userAttributes.includes(filter.type.type_tag)">
                        <CInput v-debounce:1s="getTurnoverData"
                                type="text" 
                                class="attribute mb-0"
                                v-model="filter.value">
                        </CInput>
                      </div>                   
                    </div>

                    <CButton class="ml-2 mr-0 d-inline-block" color="primary" @click="removeFilterOption(group, index);">
                      <i class="fas fa-times"/>
                    </CButton>
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <CCol cols="12" lg="12" class="pt-0 pb-0">
                  <CButton color="primary" @click="addFilterOption();">
                    <i class="fas fa-plus mr-1"/>
                    <span>{{ $t('common.Add_filter') }}</span>
                  </CButton>
                </CCol>
              </CRow>           
        </div>
      </CCol>
    

    
      <CCol cols="3" xl="3" lg="3" md="3" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                <span>Headcount</span>
              </CCol>       
            </CRow>       
          </CCardHeader>          
          <CCardBody class="pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else mode="with_description" :count="turnoverInsightsData.headcount" countTitle="Headcount" :countIcon="null"/>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol cols="4" xl="4" lg="4" md="4" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                <span>Headcount per Gender</span>
              </CCol>       
            </CRow>       
          </CCardHeader>          
          <CCardBody class="pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <GenderPolarChart v-else :chartData="turnoverInsightsData.headcount_gender" :companyPrimaryColor="companyPrimaryColor"/>
          </CCardBody>
        </CCard>
      </CCol>    
      <CCol cols="6" xl="6" lg="6" md="6" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                <span>Headcount per Contract Type</span>
              </CCol>       
            </CRow>
          </CCardHeader>
          <CCardBody class="text-center pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
              <HeadcountContractChart :chartData="turnoverInsightsData.headcount_contract_type" :companyPrimaryColor="companyPrimaryColor"/>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol cols="6" xl="6" lg="6" md="6" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                <span>Headcount per Department</span>
              </CCol>       
            </CRow>
          </CCardHeader>
          <CCardBody class="text-center pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
              <HeadcountDepartmentsChart :chartData="turnoverInsightsData.headcount_departments" :companyPrimaryColor="companyPrimaryColor"/>
            </div>
          </CCardBody>
        </CCard>
      </CCol>           
      <CCol cols="9" xl="9" lg="9" md="9" sm="12">
        <CCard class="mb-0 h-100">
          <CCardHeader class="pb-0">
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                <span>Hires & leavers</span>
              </CCol>       
            </CRow>
          </CCardHeader>
          <CCardBody class="text-center pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <div v-else class="h-100 d-flex flex-column align-items-center justify-content-center">
              <HiresLeaversChart :chartData="turnoverInsightsData.hires_vs_leavers" :companyPrimaryColor="companyPrimaryColor"/>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol cols="3" xl="3" lg="3" md="3" sm="12">
        <CCard class="h-100 mb-0">
          <CCardHeader>
            <CRow>
              <CCol cols="12" xl="12" lg="12" md="12" sm="12" class="text-left pt-0 pb-0">
                <span>Average turnover rate</span>
              </CCol>       
            </CRow>       
          </CCardHeader>
          <CCardBody class="pt-0">
            <div v-if="loadingData" class="h-100 d-flex align-items-center">
              <loadingSpinner mode="auto" :content="null"/>
            </div>
            <Count v-else mode="with_description" :count="turnoverInsightsData.avg_turnover_rate_12_months.turnover_rate" countTitle="Average turnover rate 12 months" :countIcon="null"/>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>   
  </div>
</template>

<script>
import axios from 'axios'
import loadingSpinner from '@/components/common/loadingSpinner.vue';
import Multiselect from 'vue-multiselect';
import noPermission from '@/components/common/noPermission.vue';

import Count from '@/components/insights/common/Count.vue';
import BarChart from '@/components/insights/common/BarChart.vue';

import HiresLeaversChart from '@/components/insights/reports/HiresLeaversChart.vue';
import HeadcountContractChart from '@/components/insights/reports/HeadcountContractChart.vue';
import HeadcountDepartmentsChart from '@/components/insights/reports/HeadcountDepartmentsChart.vue';
import GenderPolarChart from '@/components/insights/reports/GenderPolarChart.vue';

export default {
  name: 'Turnover',
  components: {
    loadingSpinner,
    Multiselect,
    noPermission,
    Count,
    BarChart,
    HiresLeaversChart,
    HeadcountContractChart,
    HeadcountDepartmentsChart,
    GenderPolarChart
  },
  data() {
    return {
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      loadingData: false,      
      turnoverInsightsData: {
        hires_vs_leavers: [],
        headcount: 0,
        headcount_departments: [],
        headcount_contract_type: {},
        headcount_gender: [],
        avg_turnover_rate_12_months: {
          turnover_rate: 0
        }
      },
      showDownloadButtons: false,
      printingInsights: false,
      companyPrimaryColor: '#00875A',

      insightsFilters: [],
      groupFilterTypes: [],
      groupFilterConditions: [],
      typeFilterConditions: [],
      groupFilterValues: [],
      groupFilterTargetGroups: [],      
      groupFilterUsers: [],
      groupFilterDepartmentManagers: [],
      groupFilterTeamManagers: [],      
      groupFilterDepartments: [],
      groupFilterTeams: [],
      typeFilterValues: [],
      userAttributes: ['hours_on_contract', 'meyer_briggs', 'disc', 'office_based', 'division', 'language', 'persona_1', 'persona_2', 'persona_3', 'work_location', 'user_function', 'years_of_service', 'days_of_service'],
      conditionsWithoutValue: ['is_not_set', 'is_today']
    }
  },
  methods: {
    getTurnoverData() {
      let params = {};
      params.filters = this.insightsFilters;

      // Start the loader
      this.loadingData = true;   
      // Get the topics insights data
      axios.post(process.env.VUE_APP_API_URL + '/v1/insights/reports/turnover', params)
      .then(res => {
        // Set the turnoverInsightsData
        this.turnoverInsightsData = res.data.data;
        // Set timeout for 2 seconds
        setTimeout(function(){
          // Close the loader
          this.loadingData = false;              
          // Update the showDownloadButtons value
          this.showDownloadButtons = true;
        }.bind(this), 2000)                         
      })
      .catch(err => {
        console.error(err); 
      });                  
    },
    makeScreenshot() {
      window.scroll(0,0);
      // Update printingInsights value
      this.printingInsights = true;
      // Get the HTML to print
      const content = this.$refs.screenshotContent;
      // Set the html2canvas options
      const options = { type: "dataURL", useCORS: true };

      setTimeout(function() {
        // Print the screenshot
        this.printScreenshot(content, options);
        // Reset the printingInsights value
        this.printingInsights = false;
      }.bind(this), 500);
    },
    async printScreenshot(content, options) {
      // Create canvas for printing
      const printCanvas = await html2canvas(content, options);
      // Creata a link element to download the generated canvas
      const link = document.createElement("a");

      let exportSubject = 'Turnover';
      exportSubject = exportSubject.replace(/\s/g, '');
      exportSubject = exportSubject.toLowerCase();      

      link.setAttribute("download", "insights_" + exportSubject + "_" + new Date().toLocaleString() + ".png");
      link.setAttribute("href", printCanvas.toDataURL("image/png").replace("image/png", "image/octet-stream"));
      link.click();
    },
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    },
    
    getGroupFilterTypes() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types')
      .then(res => {
        this.groupFilterTypes = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterConditions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/conditions')
      .then(res => {
        this.groupFilterConditions = res.data.data;
        // Set the typeFilterConditions array
        this.typeFilterConditions = this.groupFilterConditions;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterConditions(filterTypeTag, filterIndex) {
      // Reset the typeFilterConditions array
      this.typeFilterConditions = this.groupFilterConditions;
      // Filter the typeFilterConditions based on the filter type tag
      this.typeFilterConditions = this.typeFilterConditions.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
      // Auto set the condition if the filter type has only one available
      if(this.typeFilterConditions.length === 1) {
        this.insightsFilters[filterIndex].condition = this.typeFilterConditions[0];      

        if(this.conditionsWithoutValue.includes(this.insightsFilters[filterIndex].condition.condition_tag)) {
          this.getTurnoverData();
        }
      }      
    },   
    getGroupFilterTypeValues() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/filters/types/values')
      .then(res => {
        this.groupFilterValues = res.data.data;
        // Set the typeFilterValues array
        this.typeFilterValues = this.groupFilterValues;        
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getTypeFilterValues(filterTypeTag) {
      // Reset the typeFilterValues array
      this.typeFilterValues = this.groupFilterValues;
      // Filter the typeFilterValues based on the filter type tag
      this.typeFilterValues = this.typeFilterValues.filter( i => i.available_for_filter_type_tags.includes(filterTypeTag));
    },
    setupTypeFilter(filterTypeTag, filterIndex) {
      // Get the filter type conditions
      this.getTypeFilterConditions(filterTypeTag, filterIndex);
      // Get the filter type values
      this.getTypeFilterValues(filterTypeTag);
      // Get the filter values
      if(filterTypeTag === 'department') {
        this.getGroupFilterDepartments();        
      } else if(filterTypeTag === 'team') {
        this.getGroupFilterTeams();        
      } else if(filterTypeTag === 'user') {
        this.getGroupFilterRecipients();        
      } else if(filterTypeTag === 'department_manager') {
        this.getGroupFilterDepartmentManagers();        
      } else if(filterTypeTag === 'team_manager') {
        this.getGroupFilterTeamManagers();        
      } else if(filterTypeTag === 'target_group') {
        this.getGroupFilterTargetGroups();        
      }      
      // Force update
      this.$forceUpdate();
    },    
    customFilterTypeLabel({type_tag}) {
      return this.$t('filter.' + type_tag);
    },    
    customFilterConditionLabel({condition_tag}) {
      return this.$t('condition.' + condition_tag);
    },    
    customFilterTypeValueLabel({type_value_tag}) {
      return this.$t('filter_value.' + type_value_tag);
    },
    addFilterOption() {
      this.insightsFilters.push({});
    },
    removeFilterOption(data, index) {          
      this.insightsFilters.splice(index, 1);
      this.getTurnoverData();
    },
    resetGroupData () {
      this.group = {
        title: '',
        description: '',
        filters: [],
        is_editable: false
      }
    },
    getGroupFilterTargetGroups() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/targetgroups/multiselect')
      .then(res => {
        this.groupFilterTargetGroups = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterRecipients() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/employees/multiselect')
      .then(res => {
        this.groupFilterRecipients = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterDepartmentManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/department-managers/multiselect')
      .then(res => {
        this.groupFilterDepartmentManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeamManagers() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/team-managers/multiselect')
      .then(res => {
        this.groupFilterTeamManagers = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    getGroupFilterDepartments() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/departments/multiselect')
      .then(res => {
        this.groupFilterDepartments = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    getGroupFilterTeams() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/organisation/teams/multiselect')
      .then(res => {
        this.groupFilterTeams = res.data.data;
      })
      .catch(err => {
        console.error(err); 
      });
    },    
    asyncFindTargetGroup(query) {
      let searchTerm = query;
      this.groupFilterTargetGroups = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/targetgroup/' + searchTerm)
        .then(res => {      
          this.groupFilterTargetGroups = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },          
    asyncFindUser(query) {
      let searchTerm = query;
      this.groupFilterUsers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/users/' + searchTerm)
        .then(res => {      
          this.groupFilterUsers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindDepartmentManager(query) {
      let searchTerm = query;
      this.groupFilterDepartmentManagers = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/department-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartmentManagers = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeamManager(query) {
      let searchTerm = query;
      this.groupFilterTeamManagers = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/team-managers/' + searchTerm)
        .then(res => {      
          this.groupFilterTeamManagers = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindDepartment(query) {
      let searchTerm = query;
      this.groupFilterDepartments = [];
      
      if(searchTerm.length >= 2) {      
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/departments/' + searchTerm)
        .then(res => {      
          this.groupFilterDepartments = res.data.data;
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },
    asyncFindTeam(query) {
      let searchTerm = query;
      this.groupFilterTeams = [];

      if(searchTerm.length >= 2) {
        axios.get(process.env.VUE_APP_API_URL + '/v1/common/search/teams/' + searchTerm)
        .then(res => {      
          this.groupFilterTeams = res.data.data;    
        })
        .catch(err => {
          console.error(err); 
        });
      }   
    },    

  },
  mounted: function() {
    // Set the companyPrimaryColor value
    this.companyPrimaryColor = getComputedStyle(document.documentElement).getPropertyValue('--DashboardPrimary');

    this.getGroupFilterTypes();
    this.getGroupFilterConditions();
    this.getGroupFilterTypeValues();

    this.getPlatformPermissions();
    this.getTurnoverData();
  }
}
</script>